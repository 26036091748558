import Flickity from 'flickity';
import { modalInit } from './lightbox.js';

var menuBtn = document.getElementsByClassName('menuBtn');

var searchBtn = document.getElementsByClassName('searchBtn');
var searchCloseBtn = document.getElementsByClassName('searchCloseBtn');

var menuLink = document.getElementsByClassName('menuLink');
var menuBackBtn = document.getElementsByClassName('menuBackBtn');
var subMenus = document.getElementsByClassName('subMenu');

var toggleMenu = function() {
    document.body.classList.toggle('menuOpen');
    if (document.body.classList.contains('menuOpen')) {
        menuBack();
    }
    if (document.body.classList.contains('searchOpen')) {
        closeSearch();
    }
};

var openMenu = function() {
    document.body.classList.add('menuOpen');
}

var closeMenu = function() {
    document.body.classList.remove('menuOpen');
    menuBack();

}

var openSearch = function() {
    document.body.classList.add('searchOpen');
    closeMenu();
};


var toggleSearch = function() {
    document.body.classList.toggle('searchOpen');
    document.getElementById('searchInput').value = '';
    closeMenu();

}


var closeSearch = function() {
    document.body.classList.remove('searchOpen');



};

var selectMenu = function() {

    for (var i = 0; i < subMenus.length; i++) {
        subMenus[i].classList.add('hidden');
    }

    var myMenu = document.getElementById('menu_' + this.dataset.menu);
    mainMenu.classList.add('-translate-x-full');
    myMenu.classList.remove('hidden');
};

var menuBack = function() {
    mainMenu.classList.remove('-translate-x-full');
    console.log('back working');
}

var newsletterReveal = function() {
    newsletterForm.classList.toggle('extend');
    // newsletterOptions.classList.toggle('hidden');
    console.log('newletter reveal');
}

window.addEventListener('load', function(event) {

    modalInit();


    const newsletterRevealBtn = document.querySelector('.newsletterRevealBtn');
    const newsletterOptions = document.querySelector('#newsletterOptions');
    const mainMenu = document.querySelector('#mainMenu');
    const searchWrapper = document.querySelector('#searchWrapper');
    const heightOutput = document.querySelector('#height');
    const widthOutput = document.querySelector('#width');
    const newsletterForm = document.querySelector('#newsletterForm');
    const bigText = document.querySelector('#bigText');
    const loadingBar = document.querySelector('#loadingBar');

    //Load page before applying functions to buttons

    newsletterRevealBtn.addEventListener('click', newsletterReveal, false);

    for (var i = 0; i < menuBackBtn.length; i++) {
        menuBackBtn[i].addEventListener('click', menuBack, false);
    }
    for (var i = 0; i < menuBtn.length; i++) {
        menuBtn[i].addEventListener('click', toggleMenu, false);
    }
    for (var i = 0; i < menuLink.length; i++) {
        menuLink[i].addEventListener('click', selectMenu, false);
    }
    //Search buttons
    for (var i = 0; i < searchBtn.length; i++) {
        searchBtn[i].addEventListener('click', toggleSearch, false);
    }
    for (var i = 0; i < searchCloseBtn.length; i++) {
        searchCloseBtn[i].addEventListener('click', closeSearch, false);
    }

    const searchInput = document.getElementById('searchInput');
    const searchResults = document.getElementById('searchResults');

    document.getElementById("searchForm").onkeypress = function(e) {
        var key = e.charCode || e.keyCode || 0;
        if (key == 13) {
            e.preventDefault();
        }
    }

    searchInput.addEventListener('input', function() {
        if (searchInput.value.length >= 2) {
            fetch('/?p=search/results&q=' + searchInput.value)
                .then(response => response.text())
                .then(data => {
                    searchResults.innerHTML = data;
                })
                .catch((error) => {
                    console.error('Error2:', error);
                });
        } else {
            searchResults.innerHTML = "";
        }
    });



    var elem = document.querySelector('.main-carousel');
    if (typeof(elem) != 'undefined' && elem != null) {
        elem.classList.remove('hidden');
        var flkty = new Flickity(elem, {
            // options
            pageDots: false,
            autoPlay: 4000,
            selectedAttraction: 0.05,
            friction: 0.5
        });
        /*
        flkty.on('change', function(index) {
            if (index > 0) {
                bigText.classList.add('opacity-0');
            } else {
                bigText.classList.remove('opacity-0');
            }
        });*/

    }



    loadingBar.classList.add('opacity-0');



});

//little trick for mobile height
// We listen to the resize event
let vh = window.innerHeight * 0.01;
document.documentElement.style.setProperty('--vh', `${vh}px`);

function resizeListener() {
    console.log(window.innerHeight);
    console.log(window.innerWidth);
    //little trick for mobile height
    // We listen to the resize event
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);


    if (window.innerWidth > 640) {
        console.log('remove hidden values')
        for (var i = 0; i < subMenus.length; i++) {
            subMenus[i].classList.remove('hidden');
        }
        menuBack();
    }
}

window.addEventListener("resize", resizeListener);