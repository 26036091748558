function closeModal() {
    document.getElementById("lightBoxImg").src = "";
    document.getElementById("lightBox").classList.remove('opacity-100');
    document.getElementById("lightBoxLoadingBar").classList.add('hidden');
    document.getElementById("lightBox").classList.add('opacity-0');
    document.getElementById("lightBox").classList.add('pointer-events-none');
    document.getElementById("lightBoxLoadingBar").classList.remove('loadingBar');
    document.getElementById("lightBox").removeEventListener('click', closeModal);
}

function openModal(evt) {
    document.getElementById("lightBox").classList.remove('pointer-events-none');
    document.getElementById("lightBoxLoadingBar").classList.remove('hidden');
    document.getElementById("lightBoxLoadingBar").classList.add('loadingBar');
    document.getElementById("lightBox").classList.remove('opacity-0');
    document.getElementById("lightBox").classList.add('opacity-100');
    document.getElementById("lightBoxImg").src = evt.target.currentSrc;
    document.getElementById("lightBox").addEventListener('click', closeModal, false);



}



function modalInit() {

    var openModalBtn = document.getElementsByClassName('open-modal-btn');
    for (var i = 0; i < openModalBtn.length; i++) {
        openModalBtn[i].addEventListener('click', openModal, false);
    }

}


export { modalInit }